import { Configuration, MachineInactivity } from '../../lib/lib';

import { MachineTicketService } from './machine-ticket.service';
import { MachineBaseService } from './machine-base.service';
import { MachineSaleShopService } from './machine-sale.service';
import { MachineNonOperationalService } from './machine-non-operational.service';
import { MachinePayoutService } from './machine-payout.service';
import { LanguageService } from '../language.service';
import { MessageType, Message } from '../message.service';
import { StoreHistoryService } from '../store-history.service';
import { MachineScreenSaverService } from './machine-screen-saver.service';
import { MachineLiteSaleService } from './lite/machine-lite-sale.service';
import { MachineTicketActivationService } from './machine-ticket-activation.service';
import { MachineGateService } from './machine-gate.service';
import { ModalService } from '../gui/modal/modal-service';
import { NotificationService } from '../notification/notification.service';
import { ExternalPaymentService } from '../../modules/external-payment/services/external-payment.service';
import { ShopRedirectService } from '../shop-redirect.service';
import { ExternalTicketService } from '../../modules/external-ticket/services/external-ticket.service';
import { MachineMoneyExchangeService } from './machine-money-exchange.service';
import { ExternalCardRechargeModel } from '../../modules/external-payment/models/external-card-recharge-model';
import { MachineCardRechargeService } from './machine-card-recharge.service';
import { ExternalUseService } from 'src/app/modules/external-base/services/external-use.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MachineRootService extends MachineBaseService {
  private machineSaleShop: MachineSaleShopService;
  private machineLiteSaleShop: MachineLiteSaleService;
  private machineNonOperational: MachineNonOperationalService;
  private machineTicket: MachineTicketService;
  private machinePayout: MachinePayoutService;
  private beforePendingState: string = null;
  private languageService: LanguageService;
  private storeHistoryService: StoreHistoryService;
  private machineScreenSaverService: MachineScreenSaverService;
  private isLiteMode: boolean;
  private machineTicketActivationService: MachineTicketActivationService;
  private machineGateService: MachineGateService;
  private modalService: ModalService;
  private notificationService: NotificationService;
  private externalPaymentService: ExternalPaymentService;
  private shopRedirectService: ShopRedirectService;
  private externalTicketService: ExternalTicketService;
  private machineMoneyExchangeService: MachineMoneyExchangeService;
  private machineCardRechargeService: MachineCardRechargeService;
  private externalUseService: ExternalUseService;

  init(): void {
    this.languageService = this.injector.get(LanguageService);
    this.machineSaleShop = this.injector.get(MachineSaleShopService);
    this.machineSaleShop.eventSwitchedOff.subscribe(() => this.onMachineSaleSwitchedOff());

    this.machineLiteSaleShop = this.injector.get(MachineLiteSaleService);
    this.machineLiteSaleShop.eventSwitchedOff.subscribe(() => this.onMachineSaleSwitchedOff());

    this.machinePayout = this.injector.get(MachinePayoutService);
    this.machinePayout.eventSwitchedOff.subscribe(() => this.onMachinePayoutSwitchedOff());

    this.machineTicket = this.injector.get(MachineTicketService);
    this.machineTicket.eventSwitchedOff.subscribe(() => this.onMachineTicketSwitchedOff());
    this.machineTicket.eventPending.subscribe(() => this.onMachineTicketPending());

    this.machineNonOperational = this.injector.get(MachineNonOperationalService);
    this.machineNonOperational.eventSwitchedOff.subscribe(() => this.onMachineNonOperationalSwitchedOff());
    this.machineNonOperational.eventPending.subscribe(() => this.onMachineNonOperationalPending());

    this.storeHistoryService = this.injector.get(StoreHistoryService);

    this.machineScreenSaverService = this.injector.get(MachineScreenSaverService);

    this.machineTicketActivationService = this.injector.get(MachineTicketActivationService);
    this.machineTicketActivationService.eventSwitchedOff.subscribe(() => this.onMachineTicketActivationSwitchedOff());
    this.machineGateService = this.injector.get(MachineGateService);

    this.modalService = this.injector.get(ModalService);
    this.notificationService = this.injector.get(NotificationService);

    this.externalPaymentService = this.injector.get(ExternalPaymentService);
    this.shopRedirectService = this.injector.get(ShopRedirectService);

    this.externalTicketService = this.injector.get(ExternalTicketService);

    this.machineMoneyExchangeService = this.injector.get(MachineMoneyExchangeService);
    this.machineMoneyExchangeService.eventSwitchedOff.subscribe(() => this.onMachineMoneyExchangeSwitchedOff());
    this.machineCardRechargeService = this.injector.get(MachineCardRechargeService);

    this.externalUseService = this.injector.get(ExternalUseService);

    super.init();
    this.hardResetRoot();
    this.dispatcherService.onConfigurationChangedSubscribe(x => this.onConfigurationChangedSubscribe(x));
    this.dispatcherService.eventUserActivity.subscribe(() => this.onUserActivity());
    this.isLiteMode = this.additionalPropertiesConfigurationService.isLiteMode;
  }

  get machineName(): string {
    return 'Root Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toPending', from: '*', to: 'pending' },
      { name: 'toIdle', from: '*', to: 'idle' },
      { name: 'toSale', from: ['idle', 'ticket', 'payoutWithoutNavigation', 'externalUse'], to: 'sale' },
      { name: 'toInfo', from: ['idle', 'info'], to: 'info' },
      { name: 'toTicket', from: '*', to: 'ticket' },
      { name: 'toTicketActivation', from: '*', to: 'ticketActivation' },
      { name: 'toMoneyExchange', from: '*', to: 'moneyExchange' },
      { name: 'toExternalPayout', from: '*', to: 'externalPayout' },
      { name: 'toPayout', from: ['ticket', 'externalPayout'], to: 'payout' },
      { name: 'toExternalCardRecharge', from: ['*'], to: 'externalCardRecharge' },
      { name: 'toPayoutWithoutNavigation', from: 'sale', to: 'payoutWithoutNavigation' },
      { name: 'toNonOperational', from: '*', to: 'nonOperational' },
      { name: 'toExternalUse', from: '*', to: 'externalUse' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToIdle: () => {
        if (scope.isExitRole) {
          scope.doAsync(() => scope.machine.toTicket(), 'onToIdle');
        } else if (scope.configurationService.showArticlesOnMainPage || scope.additionalPropertiesConfigurationService.isLiteMode) {
          scope.doAsync(() => scope.machine.toSale(), 'onToIdle.');
          scope.doAsync(() => scope.machineScreenSaverService.machineStart(), 'onToIdle. screenSaver - start');
        } else if (this.externalPaymentService.isEnabled) {
          scope.doAsync(() => scope.machine.toSale(), 'onToIdle.');
        } else if (this.externalTicketService.isEnabled) {
          scope.doAsync(() => scope.machine.toTicket(), 'onToIdle.');
        } else if (this.externalUseService.isEnabled) {
          scope.doAsync(() => scope.machine.toSale(), 'onToIdle.');
          scope.doAsync(() => scope.machine.toExternalUse(), 'onToIdle.');
        } else {
          if (this.shopRedirectService.tryRedirectToShop()) {
            return;
          }
          scope.router.navigateByUrl('/');
          scope.doAsync(() => scope.machineScreenSaverService.machineStart(), 'onToIdle. screenSaver - start');
        }
      },
      onToPending: () => {
        scope.beforePendingState = 'sale';
        scope.doAsync(() => scope.machineScreenSaverService.machineStop(), 'onToPending. screenSaver');
        scope.doAsync(() => scope.machineLiteSaleShop.machineStop(), 'onToPending. machineLiteSaleShop');
        scope.doAsync(() => scope.machineSaleShop.machineStop(), 'onToPending. machineSaleShop');
        scope.doAsync(() => scope.machineTicket.machineStop(), 'onToPending. machineTicket');
        scope.doAsync(() => scope.machineTicketActivationService.machineStop(), 'onToPending. machineTicketActivationService');
        scope.doAsync(() => scope.machineGateService.machineStop(), 'onToPending. gate');
      },
      onLeavePending: () => {
        scope.beforePendingState = null;
        scope.doAsync(() => scope.machineScreenSaverService.machineStop(), 'onLeavePending. screenSaver');
      },
      onToSale: () => {
        if (!scope.configurationService.showArticlesOnMainPage) {
          scope.doAsync(() => scope.machineScreenSaverService.machineStop(), 'onToSale. screenSaver');
        }
        if (scope.additionalPropertiesConfigurationService.isLiteMode) {
          scope.doAsync(() => scope.machineLiteSaleShop.machineStart(), 'onToSale');
        } else {
          if (scope.machineSaleShop.state === 'off') {
            scope.doAsync(() => scope.machineSaleShop.machineStart(), 'onToSale');
          }
        }
      },
      onToNonOperational: () => {
        scope.machineTicket.machineStop();
        scope.doAsync(() => scope.machineGateService.machineStop(), 'onToNonOperational. gate');
        scope.doAsync(() => scope.machineScreenSaverService.machineStart(), 'onToNonOperational. screenSaver');
        scope.doAsync(() => scope.machineNonOperational.machineStart(), 'onToNonOperational');
      },
      onToInfo: () => {
        scope.doAsync(() => scope.machineScreenSaverService.machineStop(), 'onToInfo. screenSaver');
        scope.router.navigateByUrl('/visual-items/info');
      },
      onToTicket: () => {
        scope.doAsync(() => scope.machineScreenSaverService.machineStop(), 'onToTicket. screenSaver');
        scope.doAsync(() => scope.machineTicket.machineStart(), 'onToTicket');
      },
      onToTicketActivation: () => {
        scope.doAsync(() => scope.machineScreenSaverService.machineStop(), 'onToTicketActivation. screenSaver');
        scope.doAsync(() => scope.machineTicketActivationService.machineStart(), 'onToTicketActivation');
      },
      onToExternalPayout: () => {
        scope.log.info('onToPayout');
        scope.doAsync(() => scope.machine.toPayout(), 'onToPayout');
      },
      onToPayout: () => {
        scope.log.info('onToPayout');
        scope.machinePayout.externalPayment = scope.externalPaymentService.isEnabled;
        scope.doAsync(() => scope.machineScreenSaverService.machineStop(), 'onToPayout. screenSaver');
        scope.doAsync(() => scope.machinePayout.machineStart(), 'onToPayout');
      },
      onToPayoutWithoutNavigation: () => {
        scope.log.info('onToPayoutWithoutNavigation');
        scope.doAsync(() => scope.machineScreenSaverService.machineStop(), 'onToPayoutWithoutNavigation. screenSaver');
        scope.machinePayout.disableNavigation = true;
        scope.doAsync(() => scope.machinePayout.machineStart(), 'onToPayoutWithoutNavigation');
      },
      onToMoneyExchange: () => {
        scope.doAsync(() => scope.machineScreenSaverService.machineStop(), 'onToMoneyExchange. screenSaver');
        scope.doAsync(() => scope.machineMoneyExchangeService.machineStart(), 'onToMoneyExchange');
      },
      onToExternalCardRecharge: (event, model: ExternalCardRechargeModel) => {
        scope.machineCardRechargeService.externalCardRechargeModel = model;
        scope.doAsync(() => scope.machineCardRechargeService.machineStart(), 'onToExternalCardRecharge')
      },
      onToExternalUse: () => {
        scope.doAsync(() => scope.machineScreenSaverService.machineStop(), 'onToInfo. screenSaver');
        scope.router.navigateByUrl(`/${this.externalUseService.name}`);
      }
    });
  }

  onMachineTicketSwitchedOff(): void {
    if (this.state === 'ticket') {
      if (this.machineTicket.isTicketOvertimePayment) {
        this.machine.toSale();
      } else {
        this.machine.toIdle();
      }
    }
  }

  onMachineTicketActivationSwitchedOff(): void {
    if (this.state === 'ticketActivation') {
      this.machine.toIdle();
    }
  }

  onMachineSaleSwitchedOff(): void {
    if (this.isInState('sale')) {
      this.machine.toIdle();
    } else if (this.isInState('externalUse')) {
      this.machine.toSale();
      this.machine.toExternalUse();
    } else if (this.canSwitchToNonOperational()) {
      this.machine.toNonOperational();
    }
  }

  onMachinePayoutSwitchedOff(): void {
    if (this.isInState('payout')) {
      if (this.externalPaymentService.isEnabled
        && this.shopRedirectService.tryRedirectToExternalBackUrl(this.machinePayout.paymentFailed)) {
        return;
      }

      this.machine.toIdle();
    } else if (this.isInState('payoutWithoutNavigation')) {
      if (this.machineNonOperational.isNonOperational) {
        this.machine.toPending();
      } else {
        this.machine.toSale();
        this.dispatcherService.displayModeRefundComplete(this.machinePayout.paymentFailed);
      }
    } else if (this.canSwitchToNonOperational()) {
      this.machine.toNonOperational();
    }
  }

  canSwitchToNonOperational(): boolean {
    return this.state === 'pending'
      && this.machineSaleShop.state === 'off'
      && this.machineLiteSaleShop.state === 'off'
      && this.machinePayout.state === 'off';
  }

  onMachineNonOperationalSwitchedOff(): void {
    if (this.state === 'pending') {
      this.machineSaleShop.machineStopCancel();
      this.machineLiteSaleShop.machineStopCancel();
      this.machine.goto(this.beforePendingState);
    } else if (this.state === 'nonOperational') {
      this.machine.toIdle();
    }
  }

  onMachineTicketPending(): void {
    if (this.state === 'idle') {
      this.machine.toTicket();
    }
  }

  onMachineNonOperationalPending(): void {
    if (this.isInState('payoutWithoutNavigation', 'externalUse')) {
      return;
    }
    if (this.isInState('sale', 'payout')) {
      this.machine.toPending();
    } else {
      this.machine.toNonOperational();
    }
  }

  onMachineMoneyExchangeSwitchedOff(): void {
    this.machineSaleShop.machineStop();
    this.machine.toIdle();
  }

  private hardResetRoot(): void {
    const scope = this;
    scope.log.info('MachineRootService. hardResetRoot. Begin.');
    this.storeHistoryService.reset();
    this.modalService.closeAll();
    this.notificationService.hide();
    this.messageService.sendMessage(new Message(MessageType.MachineHardReset));
    this.doAsync(() => {
      if (scope.machineNonOperational.isNonOperational) {
        scope.log.info('MachineRootService. hardResetRoot. isNonOperational');
        scope.machineNonOperational.updateState();
      } else {
        scope.machine.toIdle();
      }
      const machineInactivity = new MachineInactivity(
        false,
        this.machineName,
        scope.state,
        -1,
        false
      );
      this.dispatcherService.machineInactivityChangeTracking(machineInactivity);
      scope.log.info('MachineRootService. hardResetRoot. End.');
    }, 'hardResetRoot');
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonInfoClicked,
      MessageType.ButtonTicketClicked,
      MessageType.ButtonSaleShopClicked,
      MessageType.Back,
      MessageType.BackButtonVisualItemInfoClicked,
      MessageType.MachineHardReset,
      MessageType.MachineHardResetRoot,
      MessageType.VisualItemLeafSelected,
      MessageType.TicketOvertimePayment,
      MessageType.TicketReturnAmount,
      MessageType.TicketActivation,
      MessageType.OpenGate,
      MessageType.OpenGateStop,
      MessageType.ButtonAbortClicked,
      MessageType.ButtonMoneyExchangeClicked,
      MessageType.ExternalPayout,
      MessageType.ExternalCardRecharge,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    switch (message.messageType) {
      case MessageType.ButtonSaleShopClicked:
        this.machine.toSale();
        break;
      case MessageType.ButtonInfoClicked:
      case MessageType.BackButtonVisualItemInfoClicked:
        this.machine.toInfo();
        break;
      case MessageType.ButtonTicketClicked:
        this.machine.toTicket();
        break;
      case MessageType.TicketActivation:
        this.machine.toTicketActivation();
        break;
      case MessageType.Back:
        if (this.isInState('info', 'ticket')) {
          this.machine.toIdle();
        }
        break;
      case MessageType.MachineHardResetRoot:
        this.hardResetRoot();
        break;
      case MessageType.VisualItemLeafSelected:
        if (this.isInState('info')) {
          this.dispatcherService.visualItemsInfoSet(message.info);
          this.router.navigateByUrl('/visual-item/info');
        }
        break;
      case MessageType.ButtonMoneyExchangeClicked:
        this.machine.toMoneyExchange();
        break;
      case MessageType.ExternalPayout:
        this.machine.toExternalPayout();
        break;
      case MessageType.ExternalCardRecharge:
        this.machine.toExternalCardRecharge(message.info);
        break;
      case MessageType.TicketReturnAmount:
        if (this.isInState('ticket', 'sale')) {
          this.log.info(`MachineRootService. TicketReturnAmount`);
          const isDisplayMode = message.info as boolean;
          if (isDisplayMode) {
            this.machine.toPayoutWithoutNavigation();
          } else {
            this.machine.toPayout();
          }
        } else {
          this.log.warning(`MachineRootService. TicketReturnAmount. state is '${this.state}' but expected 'ticket' or 'sale'`);
        }
        break;
      case MessageType.OpenGate:
        if (this.isInState('sale', 'idle')) {
          if (message.info) {
            this.machineGateService.barcode = message.info.barcode;
            this.machineGateService.baseUrl = message.info.baseUrl;
            this.machineGateService.openGateType = message.info.openGateType;
            this.machineGateService.timeout = message.info.timeout;
          }
          this.machineGateService.machineStart();
        }
        break;
      case MessageType.OpenGateStop:
        this.machineGateService.barcode = message.info.barcode;
        this.machineGateService.baseUrl = message.info.baseUrl;
        this.machineGateService.machineStop();
        break;
      case MessageType.ButtonAbortClicked:
        this.hardResetRoot();
        break;
      default:
        break;
    }

    return false;
  }

  protected onConfigurationChangedSubscribe(configuration: Configuration): void {
    if (this.isInState('ticket', 'idle') || this.additionalPropertiesConfigurationService.isLiteMode !== this.isLiteMode) {
      this.hardResetRoot();
      this.isLiteMode = this.additionalPropertiesConfigurationService.isLiteMode;
    }
  }

  machineStart(): void { }

  protected get timeoutTrackingStates(): string[] {
    return ['info'];
  }

  private onUserActivity(): void {
    if (!this.isInState('idle', 'sale')) {
      this.machineScreenSaverService.machineStop();
    }
  }
}
